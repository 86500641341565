import Specialty from './Specialty';

class Branch {
  constructor() {
    this.branchKey = undefined;
    this.branchName = '';
    this.entityTypeId = 2;
    this.cityId = 0;
    this.areaId = 0;
    this.productLineId = 1;
    this.paymentCycleId = 1;
    this.specialties = [];
    this.branchTypeId = 0;
    this.AreaNeighbours = [];
  }

  setBranchTypeId = branchTypeId => {
    this.branchTypeId = branchTypeId;
    return this;
  };

  getBranchTypeId() {
    return this.branchTypeId;
  }

  setBranchKey = branchKey => {
    this.branchKey = branchKey;
    return this;
  };

  getBranchKey() {
    return this.branchKey;
  }

  setBranchName = branchName => {
    this.branchName = branchName;
    return this;
  };

  getBranchName() {
    return this.branchName;
  }

  getEntityTypeId() {
    return this.entityTypeId;
  }

  setCityId = cityId => {
    this.cityId = cityId;
    return this;
  };

  getCityId() {
    return this.cityId;
  }

  setAreaId = areaId => {
    this.areaId = areaId;
    return this;
  };

  getAreaId() {
    return this.areaId;
  }

  getProductLineId() {
    return this.productLineId;
  }

  setPaymentCycleId = paymentCycleId => {
    this.paymentCycleId = paymentCycleId;
    return this;
  };

  getPaymentCycleId() {
    return this.paymentCycleId;
  }

  addSpecialty(priceTypeId) {
    const newSpecialty = new Specialty();
    newSpecialty.setPriceTypeId(priceTypeId);
    this.specialties.push(newSpecialty);
    return newSpecialty;
  }

  removeSpecialty(specialtyIndex) {
    this.specialties.splice(specialtyIndex, 1);
  }

  getSpecialties() {
    return this.specialties;
  }

  getSpecialty(index) {
    return this.specialties[index];
  }

  setAreaNeighbours(neighbours){
    this.AreaNeighbours = neighbours;
    return this;

  }
}

export default Branch;
