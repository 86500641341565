import { API, isFound, Urls } from '@vezeeta/web-utils';

import PaymentCycle from './PaymentCycle';
import { Config } from '../../_Objects';

class PaymentCycles {
  constructor() {
    this.cached = false;
    this.paymentCycles = [];
  }

  loadPaymentCycles = async productLineId => {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        const getPaymentCyclesHeader = [
          {
            key: 'Culture',
            value: Config.getInstance().getCulture(),
          },
        ];

        const getPaymentCycles = new API();
        getPaymentCycles
          .get(`${Urls.payment.getPaymentCycles}${productLineId}`, getPaymentCyclesHeader)
          .then(response => {
            this.cached = true;
            response.data.forEach(paymentCycle => {
              this.addPaymentCycle()
                .setPaymentCycleId(paymentCycle.PaymentCycleId)
                .setNameEnglish(paymentCycle.NameEnglish)
                .setNameArabic(paymentCycle.NameArabic)
                .setCountryId(paymentCycle.CountryId)
                .setIsActive(paymentCycle.IsActive)
                .setIsDeleted(paymentCycle.IsDeleted);
            });

            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  isLoaded = () => this.cached;

  addPaymentCycle = () => {
    const newPaymentCycle = new PaymentCycle();
    this.paymentCycles.push(newPaymentCycle);
    return newPaymentCycle;
  };

  getPaymentCycles() {
    return this.paymentCycles;
  }

  getPaymentCycleById(paymentCycleId) {
    const result = this.paymentCycles.filter(
      paymentCycle => paymentCycle.getPaymentCycleId() === paymentCycleId,
    );

    if (isFound(result)) {
      return result[0];
    }
  }
}

export default PaymentCycles;
