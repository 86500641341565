import { API, Urls } from '@vezeeta/web-utils';

import SpecialtyGroup from './SpecialtyGroup';
import { Config } from '../../_Objects';

let instance = null;

class SpecialtyGroups {
  constructor() {
    this.cached = false;
    this.callCounter = 0;
    this.specialtyGroups = [];
  }

  static getInstance() {
    if (!instance) {
      instance = new SpecialtyGroups();
    }

    return instance;
  }

  async loadSpecialtyGroups() {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        const specialtyGroupsHeader = [
          {
            key: 'Culture',
            value: Config.getInstance().getCulture(),
          },
        ];

        const getSpecialtyGroups = new API();
        getSpecialtyGroups
          .get(Urls.speciality.getSpecialityGroups, specialtyGroupsHeader)
          .then(response => {
            response.data.forEach(specialtyGroup => {
              this.addSpecialtyGroup()
                .setSpecialtyGroupId(specialtyGroup.SpecialityGroupId)
                .setNameEnglish(specialtyGroup.NameEnglish)
                .setNameArabic(specialtyGroup.NameArabic)
                .setDisplayOrderEnglish(specialtyGroup.DisplayOrderEnglish)
                .setDisplayOrderArabic(specialtyGroup.DisplayOrderArabic);
            });

            this.cached = true;
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  isLoaded = () => this.cached;

  addSpecialtyGroup = () => {
    const newSpecialtyGroup = new SpecialtyGroup();
    this.specialtyGroups.push(newSpecialtyGroup);
    return newSpecialtyGroup;
  };

  getSpecialtyGroups() {
    return this.specialtyGroups;
  }
}

export default SpecialtyGroups;
