import { API, isFound, Urls } from '@vezeeta/web-utils';

import BranchType from './BranchType';

let instance = null;

class BranchTypes {
  constructor() {
    this.cached = false;
    this.branchTypes = [];
  }

  static getInstance() {
    if (!instance) {
      instance = new BranchTypes();
    }

    return instance;
  }

  /**
   * Load branchTypes array
   * @param {array} branchTypes
   */
  loadBranchTypes = async () => {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        const getBranchTypes = new API();
        getBranchTypes.get(Urls.getbookingtypes).then(response => {
          response.data.forEach(branchType => {
            const englishLanguageItemModel = branchType.BookingTypeLanguageItemModels.find(
              model => model.LanguageId === 1,
            );
            const arabicLanguageItemModel = branchType.BookingTypeLanguageItemModels.find(
              model => model.LanguageId === 2,
            );

            this.addBranchType()
              .setBranchTypeId(branchType.BookingTypeId)
              .setNameEnglish(englishLanguageItemModel.Name)
              .setNameArabic(arabicLanguageItemModel.Name)
              .setIsDeleted(branchType.IsDeleted);
          });

          this.cached = true;
          resolve();
        });
      } else {
        resolve();
      }
    });
  };

  isLoaded = () => this.cached;

  /**
   * Create a new BranchType() object, push to this.branchTypes and return the new object
   */
  addBranchType = () => {
    const newBranchType = new BranchType();
    this.branchTypes.push(newBranchType);
    return newBranchType;
  };

  getBranchTypes() {
    return this.branchTypes;
  }

  getBranchTypeById(branchTypeId) {
    const result = this.branchTypes.filter(
      branchType => branchType.getBranchTypeId() === branchTypeId,
    );

    if (isFound(result)) {
      return result[0];
    }
  }
}

export default BranchTypes;
