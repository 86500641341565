import { API, isFound, Urls } from '@vezeeta/web-utils';

import ProductLine from './productLine/ProductLine';
import { Config, User, Clinic } from '../_Objects';

let instance = null;

class ProductLines {
  constructor() {
    this.cached = false;
    this.productLines = [];
  }

  static getInstance() {
    if (!instance) {
      instance = new ProductLines();
    }

    return instance;
  }

  /**
   * Add product lines using addFinalProductLines api
   */
  addFinalProductLines = async token => {
    const addFinalProductLinesHeaders = [
      {
        key: 'AccountKey',
        value: User.getInstance().getAccountKey(),
      },
      {
        key: 'EntityKey',
        value: Clinic.getInstance().getClinicKey(),
      },
      {
        key: 'x-vzt-authentication',
        value: token,
      },
    ];

    const addFinalProductLines = await new API().get(
      Urls.productLines.addFinalProductLines,
      addFinalProductLinesHeaders,
    );
    return addFinalProductLines;
  };

  /**
   * Gets product lines from getProductLines api
   */
  loadProductLines = async () => {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        this.cached = true;
        const getProductLinesHeader = [
          {
            key: 'Culture',
            value: Config.getInstance().getCulture(),
          },
        ];

        const getRegisteredProductLinesHeader = [
          {
            key: 'AccountKey',
            value: User.getInstance().getAccountKey(),
          },
          {
            key: 'EntityKey',
            value: Clinic.getInstance().getClinicKey(),
          },
        ];

        const getProductLines = new API();
        const getRegisteredProductLines = new API();

        getProductLines
          .get(Urls.productLines.getProductLines, getProductLinesHeader)
          .then(response => {
            response.data.forEach(productLine => {
              const newProductLine = this.addProductLine();
              newProductLine
                .setProductLineId(productLine.ProductLineId)
                .setNameEnglish(productLine.NameEnglish)
                .setNameArabic(productLine.NameArabic)
                .setDescriptionEnglish(productLine.DescriptionEnglish)
                .setDescriptionArabic(productLine.DescriptionArabic)
                .setImage(productLine.Image)
                .setIcon(productLine.Icon);

              productLine.ProductLineDetails.forEach(productLineDetails => {
                const newProductLineDetails = newProductLine.addProductLineDetails();
                newProductLineDetails
                  .setProductLineDetailsId(productLineDetails.ProductLineDetailId)
                  .setPaymentCycleId(productLineDetails.PaymentCycleId)
                  .setSpecialtyGroupId(productLineDetails.SpecialityGroupId)
                  .setPriceTypeId(productLineDetails.PriceTypeId)
                  .setDescriptionEnglish(productLineDetails.DescriptionEnglish)
                  .setDescriptionArabic(productLineDetails.DescriptionArabic);

                productLineDetails.ProductLineDetailAttributes.forEach(productAttributes => {
                  const newProductLineDetailAttributes = newProductLineDetails.addProductLineAttribute(); // eslint-disable-line

                  newProductLineDetailAttributes
                    .setProductLineAttributeId(productAttributes.ProductLineDetailAttributeId)
                    .setPaymentAttributeId(productAttributes.PaymentAttributeId)
                    .setCountryId(productAttributes.CountryId)
                    .getFeesModel()
                    .setValue(productAttributes.FeesModel.Value)
                    .setIsPercentage(productAttributes.FeesModel.IsPercentage)
                    .setPaymentAttributeTypeId(productAttributes.FeesModel.PaymentAttributeTypeId)
                    .setPaymentAttributeType(productAttributes.FeesModel.PaymentAttributeType)
                    .setAllowedPaymentPlans(productAttributes.FeesModel.AllowedPaymentPlans);
                });
              });
            });
          })
          .then(() => {
            getRegisteredProductLines
              .get(Urls.productLines.getRegisteredProductLines, getRegisteredProductLinesHeader)
              .then(response => {
                this.registerProductLines(response.data);
                resolve();
              });
          });
      } else {
        resolve();
      }
    });
  };

  isLoaded = () => this.cached;

  getProductLines() {
    return this.productLines;
  }

  /**
   * Create a new product lines and returns it
   */
  addProductLine = () => {
    const newProductLine = new ProductLine();
    this.productLines.push(newProductLine);
    return newProductLine;
  };

  /**
   * Mapping through current product lines and the array fo
   * getRegisteredProducts api
   * @param {array} productLinesIds
   */
  registerProductLines = productLinesIds => {
    productLinesIds.forEach(productLinesId => {
      this.productLines.forEach(productLine => {
        if (productLine.getProductLineId() === productLinesId) {
          productLine.register();
        }
      });
    });
  };

  getProductLineById(productLineId) {
    const result = this.productLines.filter(
      productLine => productLine.getProductLineId() === productLineId,
    );

    if (isFound(result)) {
      return result[0];
    }
  }

  isProductLineAvailable(productLineId) {
    if (this.getProductLineById(productLineId)) {
      return true;
    }
    return false;
  }
}

export default ProductLines;
