/* global process */

import { API, decrypt, encrypt, convertImgToBase64, Cookie, Urls } from '@vezeeta/web-utils';

import User from './User';
import Config from './Config';

let instance = null;

class Clinic {
  constructor() {
    this.clinicKey = undefined;
    this.clinicName = process.env.REACT_APP_CLINIC_NAME;
    this.entityTypeId = 1;
    this.photo = undefined;
    this.photoExtension = '';
    this.prefixTitles = [];
    this.prefixTitleId = undefined;
  }

  static getInstance() {
    if (!instance) {
      instance = new Clinic();
    }

    return instance;
  }

  loadClinicInfo() {
    return new Promise(async resolve => {
      this.getClinicKey();

      const getPrefixTitles = new API();
      const prefixTitles = await getPrefixTitles.get(
        `${
          Urls.getPrefixTitles
        }LanguageId=1&CountryId=${Config.getInstance().getCountryId()}&IsActive=true`,
      );
      this.setPrefixTitles(prefixTitles.data);
      if (this.isAlreadyCreated()) {
        const getClinicInfoHeaders = [
          {
            key: 'AccountKey',
            value: User.getInstance().getAccountKey(),
          },
          {
            key: 'EntityKey',
            value: this.clinicKey,
          },
        ];

        const getClinicInfo = new API();
        getClinicInfo.get(Urls.entity.getEntity, getClinicInfoHeaders).then(response => {
          this.setClinicName(response.data.NameEnglish);
          this.setPrefixTitleId(response.data.PrefixTitleId);
          this.setPhoto(response.data.ImageUrl !== null ? response.data.ImageUrl : undefined);

          if (this.getPhoto()) {
            convertImgToBase64(this.getPhoto(), base64 => {
              this.setPhoto(base64);
              resolve();
            });
          } else {
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  }

  isAlreadyCreated() {
    if (this.clinicKey === undefined || this.clinicKey === '') {
      return false;
    }

    return true;
  }

  setClinicKey = clinicKey => {
    this.clinicKey = clinicKey;
    return this;
  };

  getClinicKey() {
    if (this.clinicKey === undefined) {
      const savedKey = Cookie.get(Cookie.CLINIC_KEY);
      if (savedKey) {
        this.setClinicKey(decrypt(savedKey));
      }
    }
    return this.clinicKey;
  }

  saveCookie = () => {
    Cookie.set(Cookie.CLINIC_KEY, encrypt(this.clinicKey));
  };

  setClinicName = clinicName => {
    this.clinicName = clinicName;
    return this;
  };

  getClinicName() {
    return this.clinicName;
  }

  setPrefixTitles = prefixTitles => {
    this.prefixTitles = prefixTitles;
    return this;
  };

  getPrefixTitles() {
    return this.prefixTitles;
  }

  getEntityTypeId() {
    return this.entityTypeId;
  }

  setPrefixTitleId = prefixTitleId => {
    this.prefixTitleId = prefixTitleId;
    return this;
  };

  getPrefixTitleId() {
    return this.prefixTitleId;
  }

  setPhoto = (photo, extension) => {
    this.photo = photo;
    this.photoExtension = extension;
  };

  getPhoto() {
    return this.photo;
  }

  getPhotoExtension() {
    return this.photoExtension;
  }
}

export default Clinic;
