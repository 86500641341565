import { Link } from 'react-router-dom';
import { Flex, Box } from 'grid-styled';
import styled from 'styled-components';

const AppContainer = styled(Box)`
  padding-left: 7%;
  padding-right: 7%;
  ${props => props.theme.media.desktop`
    padding-left: 14%;
    padding-right: 14%;
  `};
`;

const SignupStepContainer = styled(Box)`
  width: 100%;
  ${props => props.theme.media.desktop`
    width: 60%;   
  `};
  .signup-step {
    margin-bottom: 24px;
    padding: 0 12px;
    .content-container .title {
      margin-bottom: 12px;
    }
    ${props => props.theme.media.desktop`
      margin-bottom: 48px;
      padding: 0 48px;  
      .content-container .title {
        margin-bottom: 24px;
      } 
    `};
  }
`;

const IllustratedInputsContainer = styled(Flex)`
  flex-direction: column;
  ${props => props.theme.media.tablet`
    flex-direction: row;    
  `};
  ${props => props.theme.media.desktop`
    flex-direction: row;    
  `};
`;

const InputsContainer = styled(Box)`
  width: 90%;
  ${props => props.theme.media.desktop`
    width: 60%;   
  `};
`;

const SocialMediaContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 12px;
  button {
    margin: 0px 0px 8px;
  }
  ${props => props.theme.media.desktop`
    flex-direction: row;    
    margin-bottom: 48px;
    padding-left: 84px;
    padding-right: 84px;
    button {
      margin: 0px 16px 0px 0px;
    }
  `};
`;

const Seperator = styled(Box)`
  height: 33px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 8px;
  ${props => props.theme.media.desktop`
    margin-bottom: 28px;
`};
`;

const Circle = styled.div`
  height: 33px;
  width: 33px;
  background-color: #fff;
  border: 1.2px solid #bfc0c1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border-radius: 50%;
`;

const SeperatorText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #bfc0c1;
  text-transform: capitalize;
`;

const StyledLink = styled(Link)`
  color: #0070cd;
  display: block;
  margin: 0 4px;

  &[data-active] {
    color: red;
  }
`;

const InputFieldsContainer = styled(Box)`
  -webkit-box-flex: inherit;
  -webkit-flex-grow: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
`;

const SplitedInputLineContainer = styled(Flex)`
  position: relative;
  -webkit-box-flex: inherit;
  -webkit-flex-grow: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
`;

const PasswordInputContainer = styled(Flex)`
  position: relative;
`;

const ShowPassword = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  right: 0;
  height: 34px;
`;

const IllustrationContainer = styled(Box)`
  display: none;
  ${props => props.theme.media.desktop`
    display: block;   
  `};
`;

export {
  AppContainer,
  SignupStepContainer,
  IllustratedInputsContainer,
  InputsContainer,
  SocialMediaContainer,
  Seperator,
  Circle,
  SeperatorText,
  StyledLink,
  PasswordInputContainer,
  ShowPassword,
  InputFieldsContainer,
  SplitedInputLineContainer,
  IllustrationContainer,
};
