/* eslint-disable */

const appId = process.env.REACT_APP_FACEBOOK_ID;
const scope = 'public_profile, email';

class Facebook {
  static loadSdk() {
    window.fbAsyncInit = function() {
      FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v2.10',
      });
      FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  /**
   * When Facebook returns user's data
   * @param {object} response
   */
  static login() {
    return new Promise((resolve, reject) => {
      FB.login(
        response => {
          // console.log("Facebook Login Api, response", response)
          response.status === 'connected' ? response : null;
          FB.api(
            '/me?fields=first_name, last_name, picture, email',
            response => {
              // console.log("Facebook graph Api, response", response)
              if (response && !response.error) {
                resolve(response);
              } else {
                reject(response);
              }
            },
          );
        },
        { scope, return_scopes: true },
      );
    });
  }
}

export default Facebook;
