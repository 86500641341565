import { API, isFound, Urls } from '@vezeeta/web-utils';

import City from './City';

let instance = null;

class Cities {
  constructor() {
    this.cached = false;
    this.cities = [];
  }

  static getInstance() {
    if (!instance) {
      instance = new Cities();
    }

    return instance;
  }

  /**
   * Load cities array
   * @param {array} cities
   */
  loadCities = async () => {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        const getCities = new API();

        // eslint-disable-next-line no-underscore-dangle
        getCities.get(Urls.getCities).then(response => {
          response.data.forEach(city => {
            const englishLanguageItemModel = city.LanguageItemModels.find(
              model => model.LanguageId === 1,
            );
            const arabicLanguageItemModel = city.LanguageItemModels.find(
              model => model.LanguageId === 2,
            );
            this.addCity()
              .setCityId(city.Id)
              .setNameEnglish(englishLanguageItemModel.Name)
              .setNameArabic(arabicLanguageItemModel.Name)
              .setDisplayOrderEnglish(englishLanguageItemModel.DisplayOrder)
              .setDisplayOrderArabic(arabicLanguageItemModel.DisplayOrder)
              .setIsDeleted(city.IsDeleted)
              .setCountryId(city.CountryId)
              .setIsDefault(city.IsDefault);
          });

          this.cached = true;
          resolve();
        });
      } else {
        resolve();
      }
    });
  };

  isLoaded = () => this.cached;

  /**
   * Create a new City() object, push to this.cities and return the new object
   */
  addCity = () => {
    const newCity = new City();
    this.cities.push(newCity);
    return newCity;
  };

  getCities() {
    return this.cities;
  }

  /**
   * Filter cities by their country id
   * @param {number} countryId
   */
  filterByCountryId = countryId => {
    this.cities = this.cities.filter(city => city.getCountryId() === countryId);
    return this.cities;
  };

  getCityById(cityId) {
    const result = this.cities.filter(city => city.getCityId() === cityId);

    if (isFound(result)) {
      return result[0];
    }
  }
}

export default Cities;
