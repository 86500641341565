import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Cookie } from '@vezeeta/web-utils';
import { Header, Country, DropDown, UserPreview, Image, COLORS } from '@vezeeta/web-components';
import { withRouter } from 'react-router-dom';

import { Config, User, Countries } from '../views/signup/_objects/_Objects';
import IconsStore from './IconsStore';
import Culture from './Culture';
import './Header.scss';

class CustomHeader extends PureComponent {
  static propTypes = {
    location: PropTypes.any,
    isLoadingCountries: PropTypes.bool,
    shouldShowCounties: PropTypes.bool,

    loadCountries: PropTypes.func,
    changeCulture: PropTypes.func,
    updateConfig: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.config = Config.getInstance();
    this.user = User.getInstance();
    this.countries = Countries.getInstance();
  }

  state = {
    showCountryDropDown: this.props.shouldShowCounties,
    isLoadingCountries: this.props.isLoadingCountries,
    isLoggedIn: false,
    userPhoto: undefined,
    userName: '',
    userEmail: '',
  };

  static getDerivedStateFromProps(props, state) {
    const { isLoadingCountries } = props;

    if (isLoadingCountries !== state.isLoadingCountries) {
      return { showCountryDropDown: true, isLoadingCountries };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { isLoadingCountries } = prevProps;
    const { loadCountries, location } = this.props;

    if (isLoadingCountries !== this.state.isLoadingCountries) {
      this.toggleCountryDropDown(location.pathname);
    }

    if (prevProps.location.pathname !== location.pathname) {
      this.toggleCountryDropDown(location.pathname);
      if (location.pathname.includes('signup')) {
        loadCountries();
      }
    }
  }

  onLogout = () => {
    Cookie.getAllCookies().forEach(cookie =>
      Cookie.remove(cookie, { domain: process.env.REACT_APP_TOKEN_DOMAIN }),
    );
    window.location.reload(true);
  };

  toggleCountryDropDown = location => {
    if (location.includes('signup')) {
      if (this.user.isAlreadyRegistered()) {
        this.user.loadUserData('header').then(() => {
          this.setState({
            userPhoto: this.user.photo,
            userName: `${this.user.firstName} ${this.user.lastName}`,
            userEmail: this.user.email,
          });
        });
      }
      this.setState(
        {
          showCountryDropDown: true,
        },
        () => {
          if (this.user.isAlreadyRegistered()) {
            if (!this.state.isLoggedIn) {
              this.setState({
                isLoggedIn: true,
              });
            }
          }
        },
      );
    } else {
      this.setState({
        showCountryDropDown: false,
      });
    }
  };

  returnToHome = () => {
    const returnUrl = Cookie.get(Cookie.RETURN_URL);
    if (returnUrl) {
      const cookiesToBeKept = [Cookie.OLD_AUTH_TOKEN, Cookie.AUTH_TOKEN, Cookie.CULTURE];
      Cookie.getAllCookies().forEach(cookie => {
        if (!cookiesToBeKept.includes(cookie)) {
          Cookie.remove(cookie, {
            domain: process.env.REACT_APP_TOKEN_DOMAIN,
          });
        }
      });
      window.location = returnUrl;
    } else {
      window.location.pathname = `${Culture.getCulture()}/login`;
    }
  };

  render() {
    const { isLoadingCountries, changeCulture, updateConfig } = this.props;
    const { showCountryDropDown, userPhoto, userName, userEmail } = this.state;
    let countriesList = [];
    let currentCountry = {};
    const shouldShowCountries = showCountryDropDown && !isLoadingCountries;

    if (!isLoadingCountries) {
      countriesList = this.countries.getCountries().map(country => {
        if (country.isoCode === this.config.getCountryIsoCode()) {
          currentCountry = country;
        }
        return {
          onClick: () => {
            changeCulture(country.isoCode);
            updateConfig(country.isoCode);
          },
          component: (
            <Country
              key={country}
              language="en"
              flag={country.imageUrl}
              name={country.nameEnglish}
            />
          ),
        };
      });
    }

    const userDropDown = [
      {
        component: (
          <UserPreview
            name={userName}
            email={userEmail}
            photo={userPhoto}
            fallbackPhoto={`${process.env.REACT_APP_CDN_URL}/assets/user-placeholder.png`}
          />
        ),
      },
      {
        isDivider: true,
      },
    ];

    const newUserDropDown = [
      ...userDropDown,
      {
        text: 'Your account has been created. You can use your email to login later.',
        disabled: true,
      },
      {
        image: currentCountry.imageUrl,
        text: currentCountry.nameEnglish,
        disabled: true,
      },
      {
        icon: IconsStore.getIcon('logout'),
        text: 'Logout',
        onClick: this.onLogout,
      },
    ];

    const returnedUserDropDown = [
      ...userDropDown,
      {
        icon: IconsStore.getIcon('dash'),
        text: 'Return Home',
        onClick: this.returnToHome,
      },
      {
        icon: IconsStore.getIcon('logout'),
        text: 'Logout',
        onClick: this.onLogout,
      },
    ];

    const headerItems = this.state.isLoggedIn ? (
      <DropDown
        component={
          <Image
            src={userPhoto}
            alt={userName}
            name={userName}
            radius={30}
            fallback={`${process.env.REACT_APP_CDN_URL}/assets/user-placeholder.png`}
            bgColor={COLORS.WHITE}
            textColor={COLORS.HELP_TEXT}
          />
        }
        items={this.user.isPartialEdit() ? returnedUserDropDown : newUserDropDown}
        arrowColor="#fff"
      />
    ) : (
      shouldShowCountries && (
        <DropDown
          arrowColor="#fff"
          items={countriesList}
          component={
            <Country
              className="country-selected"
              flag={currentCountry.imageUrl}
              name={currentCountry.nameEnglish}
              language="en"
            />
          }
          className="countries--drop-down"
        />
      )
    );

    return (
      <Header
        items={headerItems}
        onLogoClick={() => {
          this.returnToHome();
        }}
        logoUrl={`${process.env.REACT_APP_CDN_URL}/assets/logo.png`}
        px="14%"
      />
    );
  }
}

export default withRouter(CustomHeader);
