import { STATUS, API, Urls } from '@vezeeta/web-utils';

import Branch from './Branch';
import { User, Clinic, Cart, ProductLines, PaymentCycles } from '../../_Objects';

let instance = null;

class BookProduct {
  constructor() {
    this.productLines = ProductLines.getInstance();
    this.registered = false;
    this.productLineId = 1;
    this.priceTypeId = 0;
    this.cached = false;
    this.branches = [];
    this.deleteBranches = [];
    this.allowedPaymentPlans = [];
    this.isPrivateClinic = false;

    this.cart = Cart.getInstance();
    this.paymentCycles = new PaymentCycles();
  }

  static getInstance() {
    if (!instance) {
      instance = new BookProduct();
    }
    return instance;
  }

  loadProductDetails = async (branches, isEdit) => {
    await new Promise(resolve => {
      this.paymentCycles.loadPaymentCycles(this.productLineId).then(() => {
        if (!this.isCached()) {
          if (branches) {
            this.assignBranches(branches).then(() => {
              this.registered = true;
              this.cached = true;
              resolve();
            });
          } else {
            const getBookDetailsHeader = [
              {
                key: 'AccountKey',
                value: User.getInstance().getAccountKey(),
              },
              {
                key: 'EntityKey',
                value: Clinic.getInstance().getClinicKey(),
              },
            ];

            const getBookDetails = new API();
            const Url = isEdit
              ? Urls.productLines.getEditableProductLines
              : Urls.productLines.getCustomizeBook;
            getBookDetails.get(Url, getBookDetailsHeader).then(response => {
              if (response.data) {
                this.assignBranches(response.data, isEdit).then(() => {
                  this.registered = true;
                  this.cached = true;
                  resolve();
                });
              } else {
                if (this.branches.length === 0) {
                  const newBranch = this.addBranch();
                  newBranch.addSpecialty();
                }
                this.cached = true;
                resolve();
              }
            });
          }
        } else {
          resolve();
        }
      });
    });
  };

  setClinicType = clinicType => {
    this.isPrivateClinic = clinicType;
    return this;
  };

  getClinicType = () => {
    return this.isPrivateClinic;
  };

  assignBranches = (branches, isEdit) =>
    new Promise((resolve, reject) => {
      if (branches) {
        const responseBranches = isEdit ? branches : branches;
        this.setPriceTypeId(responseBranches.PriceTypeId);
        this.setClinicType(responseBranches.IsPrivateClinic);
        responseBranches.Branches.forEach(branch => {
          const newBranch = this.addBranch();
          newBranch
            .setBranchKey(branch.BranchKey)
            .setBranchName(branch.NameEnglish)
            .setAreaId(branch.AreaId)
            .setBranchTypeId(branch.BookingTypeId);

          branch.SpecialityGroups.forEach(specialtyGroup => {
            const newSpecialty = newBranch.addSpecialty(branches.PriceTypeId);

            if (!isEdit) {
              newSpecialty
                .setSpecialtyGroupId(specialtyGroup.SpecialityGroupId)
                .setPricePerSpecialty(specialtyGroup.FeesModel.Value)
                .setAllowedPaymentPlans(specialtyGroup.FeesModel.AllowedPaymentPlans)
                .setNumberOfRooms(specialtyGroup.NumberOfRooms);
            } else {
              newSpecialty
                .setSpecialtyGroupId(specialtyGroup.SpecialityGroupId)
                .setPricePerSpecialty(undefined)
                .setAllowedPaymentPlans(undefined)
                .setNumberOfRooms(specialtyGroup.NumberOfRooms);
            }
          });
        });
        resolve();
      } else {
        reject();
      }
    });

  isCached = () => this.cached;

  getProductLineId() {
    return this.productLineId;
  }

  setPriceTypeId = priceTypeId => {
    this.priceTypeId = priceTypeId;
    return this;
  };

  getPriceTypeId() {
    return this.priceTypeId;
  }

  addBranch = () => {
    const newBranch = new Branch();
    this.branches.push(newBranch);
    return newBranch;
  };

  removeBranch = branchIndex => {
    this.deleteBranches.push(this.branches[branchIndex].getBranchKey());
    this.branches.splice(branchIndex, 1);
  };

  getDeletedBranches = () => this.deleteBranches;

  getBranches() {
    return this.branches;
  }

  getBranch(index) {
    return this.branches[index];
  }

  setBranchesKeys(keys) {
    this.branches.forEach((branch, index) => branch.setBranchKey(keys[index]));
  }

  register = () => {
    this.registered = true;
    this.productLines.getProductLineById(this.productLineId).register();
  };

  isAlreadyRegistered = () => this.registered;

  setAllowedPaymentPlans(allowedPaymentPlans) {
    this.allowedPaymentPlans = allowedPaymentPlans;
    return this;
  }

  getAllowedPaymentPlans() {
    return this.allowedPaymentPlans;
  }

  addToCart = () => {
    let { product } = this.cart.isProductInCart(this.getProductLineId());
    const { isInCart } = this.cart.isProductInCart(this.getProductLineId());

    if (isInCart) {
      product.clearItems();
    } else {
      product = this.cart.addProduct();
      product
        .setProductInstance(this)
        .setProductName('Vezeeta Book')
        .setProductUrl('book')
        .setProductId(this.getProductLineId());
    }

    this.getBranches().forEach(branch => {
      let totalPrice = 0;
      let totalRooms = 0;
      branch.getSpecialties().forEach(specialty => {
        totalPrice += specialty.getPricePerSpecialty() * specialty.getNumberOfRooms();
        totalRooms += specialty.getNumberOfRooms();
        this.cart.setAllowedPaymentPlans(specialty.getAllowedPaymentPlans());
      });

      product
        .addItem()
        .setNumberOfUnits(totalRooms)
        .setUnit(totalRooms > 1 ? 'Rooms' : 'Room')
        .setBranchName(branch.getBranchName())
        .setBranchAreaId(branch.areaId)
        .setTotalPrice(totalPrice)
        .setPaymentCycle(
          this.paymentCycles.getPaymentCycleById(branch.getPaymentCycleId()).getNameEnglish(),
        );
    });
  };

  remove = () =>
    new Promise(resolve => {
      const deleteBookHeaders = [
        {
          key: 'AccountKey',
          value: User.getInstance().getAccountKey(),
        },
        {
          key: 'EntityKey',
          value: Clinic.getInstance().getClinicKey(),
        },
      ];

      const deleteBook = new API();
      deleteBook.post(Urls.productLines.deleteBook, '', deleteBookHeaders).then(response => {
        if (response.status === STATUS.SUCCESS || response.status === STATUS.CREATED) {
          this.branches = [];
          this.registered = false;
          this.productLines.getProductLineById(this.productLineId).unRegister();

          const newBranch = this.addBranch();
          newBranch.addSpecialty();
          resolve();
        }
      });
    });
}

export default BookProduct;
