import { API, isFound, Urls } from '@vezeeta/web-utils';

import Product from './Product';
import { User, Clinic, CareProduct, BookProduct, ProductLines, Config } from '../../_Objects';

let instance = null;

class Cart {
  constructor() {
    this.cached = false;
    this.activationFees = 171;
    this.subtotal = 0;
    this.tax = 0.14;
    this.taxName = 'VAT';
    this.activationFeesName = 'Activation Fees (including taxes)';
    this.total = 0;
    this.toBePaid = 0;
    this.products = [];
    this.removedProducts = [];
    this.allowedPaymentPlans = undefined;
  }

  static getInstance() {
    if (!instance) {
      instance = new Cart();
    }

    return instance;
  }

  async loadCartDetails() {
    await new Promise(resolve => {
      this.productLines = ProductLines.getInstance();
      this.bookProduct = BookProduct.getInstance();
      this.careProduct = CareProduct.getInstance();

      if (!this.isLoaded()) {
        const accountKey = User.getInstance().getAccountKey();
        const clinicKey = Clinic.getInstance().getClinicKey();
        if (accountKey && clinicKey) {
          const getCartDetailsHeaders = [
            {
              key: 'AccountKey',
              value: accountKey,
            },
            {
              key: 'EntityKey',
              value: clinicKey,
            },
          ];

          if (!this.careProduct.isCached() || !this.bookProduct.isCached()) {
            Promise.all([
              this.productLines.loadProductLines(),
              this.loadTaxAndActivationFees(),
            ]).then(() => {
              const getCartDetails = new API();
              getCartDetails
                .get(Urls.productLines.getCartDetails, getCartDetailsHeaders)
                .then(response => {
                  Promise.all([
                    this.loadCareDetails(response),
                    this.loadProductDetails(response),
                  ]).then(() => {
                    this.cached = true;
                    resolve();
                  });
                });
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  async loadProductDetails(response) {
    await new Promise(resolve => {
      if (this.productLines.isProductLineAvailable(this.bookProduct.getProductLineId())) {
        if (response.data.BookDetails !== null) {
          if (!this.bookProduct.isCached()) {
            const bookDetails = response.data.BookDetails;

            this.bookProduct.loadProductDetails(bookDetails).then(() => {
              this.bookProduct.addToCart();
              resolve();
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  async loadCareDetails(response) {
    await new Promise(resolve => {
      if (this.productLines.isProductLineAvailable(this.careProduct.getProductLineId())) {
        if (response.data.CareDetails !== null) {
          if (!this.careProduct.isCached()) {
            const careDetails = response.data.CareDetails;

            this.careProduct
              .loadCareDetails(
                careDetails.NumberOfDoctors,
                careDetails.PaymentFrequency,
                careDetails.FeesModel[0].Value,
              )
              .then(() => {
                this.careProduct.addToCart();
                resolve();
              });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  isLoaded = () => this.cached;

  addProduct = () => {
    const newProduct = new Product();
    this.products.push(newProduct);
    return newProduct;
  };

  isProductInCart = productId => {
    const result = this.products.filter(product => product.getProductId() === productId);

    if (isFound(result)) {
      return { isInCart: true, product: result[0] };
    }
    return { isInCart: false, product: undefined };
  };

  removeProduct = index => {
    this.products.splice(index, 1);
  };

  removeProducts = async () => {
    await new Promise(resolve => {
      if (this.isThereProductsToBeDeleted()) {
        this.products.forEach((product, index) => {
          if (product.isToBeRemoved()) {
            return product
              .getProductInstance()
              .remove()
              .then(() => {
                this.removedProducts.push(true);
                this.removeProduct(index);

                if (this.isAllProductsRemoved()) {
                  resolve();
                }
              });
          }

          this.setAllowedPaymentPlans(product.getProductInstance().getAllowedPaymentPlans());
        });
      } else {
        resolve();
      }
    });
  };

  isThereProductInCart() {
    let productsCount = this.products.length;
    this.products.forEach(product => {
      if (product.isToBeRemoved()) {
        productsCount -= 1;
      }
    });

    return productsCount >= 1;
  }

  isAllProductsRemoved = () => {
    let isAllRemoved = true;
    this.removedProducts.forEach(success => {
      if (!success) {
        isAllRemoved = !success;
      }
    });

    return isAllRemoved;
  };

  isThereProductsToBeDeleted = () => {
    let isThereProductsToBeDeleted = false;

    this.products.forEach(product => {
      if (product.isToBeRemoved()) {
        isThereProductsToBeDeleted = true;
      }
    });
    return isThereProductsToBeDeleted;
  };

  getProducts() {
    return this.products;
  }

  calculateSubtotal = () => {
    this.subtotal = 0;
    this.products.forEach(product => {
      if (!product.isToBeRemoved()) {
        product.getItems().forEach(item => {
          this.subtotal += item.getTotalPrice();
        });
      }
    });

    return this.subtotal;
  };

  setActivationFees = activationFees => {
    this.activationFees = activationFees;
    return this;
  };

  getActivationFees() {
    return this.activationFees;
  }

  setTax = tax => {
    this.tax = tax;
    return this;
  };

  loadTaxAndActivationFees() {
    return new Promise(resolve => {
      if (!this.isLoaded()) {
        // TODO: Match paymentAttributeType, paymentAttributeTypeId
        const accountKey = User.getInstance().getAccountKey();
        const culture = Config.getInstance().getCulture();
        if (accountKey && culture) {
          const getCountryPaymentAttributesHeaders = [
            {
              key: 'AccountKey',
              value: accountKey,
            },
            {
              key: 'Culture',
              value: culture,
            },
          ];

          const getCountryPaymentAttributes = new API();
          getCountryPaymentAttributes
            .get(Urls.payment.getCountryPaymentAttributes, getCountryPaymentAttributesHeaders)
            .then(response => {
              if (response.data[0].IsPercentage) {
                this.setTax(response.data[0].Value / 100);
              } else {
                this.setTax(response.data[0].Value);
              }

              this.setTaxName(response.data[0].Name);
              this.setActivationFeesName(response.data[1].Name);

              if (response.data[1].IsPercentage) {
                this.setActivationFees(response.data[1].Value / 100);
              } else {
                this.setActivationFees(response.data[1].Value);
              }

              resolve();
            });
        }
      } else {
        resolve();
      }
    });
  }

  setTaxName = taxName => {
    this.taxName = taxName;
    return this;
  };

  getTaxName() {
    return this.taxName;
  }

  setActivationFeesName(activationFeesName) {
    this.activationFeesName = activationFeesName;
    return this;
  }

  getActivationFeesName() {
    return this.activationFeesName;
  }

  calculateTax = () => (this.subtotal * this.tax).toFixed(2);

  calculateTotal = () => {
    this.total = (this.subtotal + this.subtotal * this.tax + this.activationFees).toFixed(2);
    return this.total;
  };

  setAllowedPaymentPlans = (allowedPaymentPlans, override) => {
    if (!this.allowedPaymentPlans || override) {
      this.allowedPaymentPlans = allowedPaymentPlans;
    } else {
      const commonAllowedPaymentPlans = allowedPaymentPlans.filter(
        value => this.allowedPaymentPlans.indexOf(value) !== -1,
      );

      this.allowedPaymentPlans = commonAllowedPaymentPlans;
    }
  };

  isPaymentPlanAllowed(paymentPlanKey) {
    const result = this.allowedPaymentPlans.filter(paymentPlan => paymentPlan === paymentPlanKey);

    if (isFound(result)) {
      return true;
    }
    return false;
  }

  getToBePaid() {
    return this.toBePaid;
  }

  setAsPrePayment = () => {
    this.toBePaid = this.calculateTotal();
  };

  setAsPostPayment = () => {
    this.toBePaid = this.activationFees;
  };
}

export default Cart;
