/*eslint-disable*/

import Loadable from 'react-loadable';
import { Loading } from '@vezeeta/web-components';

// Async loading components
const Signup = Loadable({
  loader: () => import('./signup/Signup'),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import('./login/Login'),
  loading: Loading,
});

const ForgetPassword = Loadable({
  loader: () => import('./forgetPassword/ForgetPassword'),
  loading: Loading,
});

const Landing = Loadable({
  loader: () => import('./landing/Landing'),
  loading: Loading,
});

const Routes = [
  {
    component: Login,
    route: '/login',
  },
  {
    component: Signup,
    route: '/signup',
  },
  {
    component: ForgetPassword,
    route: '/forget-password',
  },
  {
    component: Landing,
    route: '/landing',
  },
];

export {
    Routes
};
